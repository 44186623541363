import { Controller } from "@hotwired/stimulus";
import * as Turbo from "@hotwired/turbo";
import { fetchApi } from "helpers/reactQueryApi";

// Connects to data-controller="administration--invites--send-batch-actions"
export default class extends Controller {
  connect() {}

  refreshInvites() {
    const invite_ids = this.#getSelectedInviteIds();

    if (invite_ids.length > 1) {
      this.renewInvites(invite_ids);
    } else {
      const model = this.#findInvite(invite_ids[0]);

      Tixxt.Modal.instance().showBodied(
        I18n.t("js.invites.index.details-modal.title"),
        new Tixxt.Invites.BatchRenewInviteView({ model, invite_ids }),
      );
    }
  }

  async renewInvites(invite_ids) {
    const { externalInvitesCount, groupInvitesCount } =
      this.countSelectedInviteTypes();

    try {
      window.bridge?.confirm(
        I18n.t("js.invites.batch_renew.confirm", {
          group_invites_count: groupInvitesCount,
          network_invites_count: externalInvitesCount,
        }),
        () => {
          fetchApi("/batch_invites/renew", {
            method: "POST",
            body: {
              body: "",
              invite_ids: invite_ids,
            },
          }).then(() => this.batchRenewSuccess());
        },
      );
    } catch (e) {
      this.batchRenewFailure();
    }
  }

  revokeInvites() {
    $.ajax({
      url: "/batch_invites/revoke",
      method: "post",
      data: {
        invite_ids: this.#getSelectedInviteIds(),
      },
    })
      .done(this.#submitSuccess)
      .fail(this.#submitFailure);
  }

  #getSelectedInviteIds() {
    const selectedCheckboxes = this.element.querySelectorAll(
      'input[name="invite_select"]:checked',
    );
    return Array.from(selectedCheckboxes).map(
      (checkbox) => checkbox.id.split("-")[1],
    );
  }

  #findInvite(id) {
    const model = new Tixxt.Invites.Invite({ id: id });
    model.fetch();
    return model;
  }

  countSelectedInviteTypes() {
    let externalInvitesCount = 0;
    let groupInvitesCount = 0;

    const selectedCheckboxes = this.element.querySelectorAll(
      'input[name="invite_select"]:checked',
    );

    Array.from(selectedCheckboxes).forEach((checkbox) => {
      if (checkbox.dataset.type === "ExternalInvite") {
        externalInvitesCount += 1;
      } else {
        groupInvitesCount += 1;
      }
    });

    return { externalInvitesCount, groupInvitesCount };
  }

  #submitSuccess() {
    toastr.success(I18n.t("js.invites.batch_revoke.success_flash"));
    Turbo.visit(location.pathname, {
      frame: "external-invites-view",
      action: "replace",
    });
  }

  #submitFailure() {
    toastr.error(I18n.t("js.invites.batch_revoke.not_revokable_flash"));
  }

  batchRenewSuccess() {
    toastr.success(I18n.t("js.invites.batch_renew.success_flash"));
    Turbo.visit(location.pathname, {
      frame: "external-invites-view",
      action: "replace",
    });
  }

  batchRenewFailure() {
    toastr.error(I18n.t("js.invites.batch_renew.not_renewable_flash"));
  }
}
